<template>
  <div class="quota-single">
    <div class="quota-day__time">
      <div v-if="!isMobile" class="desktopWrapper">
        <span class="quota-day__time-text">с</span>

        <span class="quota-day__time-val">
          {{ getCurrentTime(getData.time_from) }}
        </span>

        <span class="quota-day__time-text">по</span>

        <span class="quota-day__time-val">
          {{ getCurrentTime(getData.time_to) }}
          <span
            v-if="checkLongDay.isLong"
            class="quota-day__time-val quota-day__time-val--long"
          >
            /&nbsp;{{ checkLongDay.finishDate }}
          </span>
        </span>
      </div>

      <div v-else class="mobileWrapper">
        <span class="quota-day__time-val">
          {{ getCurrentTime(getData.time_from) }}
        </span>

        <span class="quota-day__title-slash">-</span>

        <span class="quota-day__time-val">
          {{ getCurrentTime(getData.time_to) }}
          <span v-if="checkLongDay.isLong" class="quota-day__title-slash">
            /
          </span>
          <span
            v-if="checkLongDay.isLong"
            class="quota-day__time-val quota-day__time-val--long"
          >
            {{ checkLongDay.finishDate }}
          </span>
        </span>
      </div>

      <div v-if="isMobile" class="quota-day__icons">
        <IconPenMobile
          v-if="!isSupport && !isViewer && !isArchive"
          @onClick="handleEditQuota"
        />
        <IconTrashMobile
          v-if="!isExporter && !isSupport && !isViewer && !isArchive"
          @onClick="handleDeleteQuota"
        />
        <div class="details-show-icon">
          <Icon
            name="ArrowDownIcon"
            width="22px"
            height="22px"
            :class="['arrow', { rotated: isOpen }]"
            @onClick="isOpen = !isOpen"
          />
        </div>
      </div>
    </div>

    <QuotaContent :quota="getData" :is-archive="isArchive" />
    <el-collapse-transition>
      <QuotaDetail
        v-show="isOpen && isMobile"
        :quota="getData"
        :is-archive="isArchive"
        class="mobile-detail"
      />
    </el-collapse-transition>
  </div>
</template>

<script>
import { DATE_FORMAT, GET_DAY, GET_TIME } from '@/constants/date'
import { GET_IS_MOBILE } from '@/store/actions'
import { GET_PREPARE_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import {
  SUPERVISOR_DELETE_QUOTA,
  SUPERVISOR_EDIT_QUOTA,
} from '@/constants/dialogs'
import { getTzTime } from '@/core'
import { mapGetters } from 'vuex'
import Icon from '@/UI/icon/Icon.vue'
import IconPenMobile from '@/UI/icon/IconTrashMobile'
import IconTrashMobile from '@/UI/icon/IconPenMobile'
import QuotaContent from './QuotaContent'
import QuotaDetail from './QuotaDetail'
export default {
  name: 'QuotaSingle',
  components: {
    QuotaDetail,
    Icon,
    QuotaContent,
    IconTrashMobile,
    IconPenMobile,
  },
  props: {
    quota: {
      type: Array,
      required: true,
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      getCultures: GET_PREPARE_CULTURE_FROM_STATE,
      isMobile: GET_IS_MOBILE,
    }),
    getData() {
      return this.quota[0]
    },
    checkLongDay() {
      return {
        isLong:
          getTzTime(this.getData.time_from, DATE_FORMAT) !==
          getTzTime(this.getData.time_to, DATE_FORMAT),
        finishDate: getTzTime(this.getData.time_to, GET_DAY),
      }
    },
  },
  methods: {
    getCurrentTime(date) {
      return getTzTime(date, GET_TIME)
    },
    handleEditQuota() {
      this.setDialog({
        name: SUPERVISOR_EDIT_QUOTA,
        data: { ...this.getData, isEdit: true },
        visible: true,
      })
    },

    handleDeleteQuota() {
      this.setDialog({
        name: SUPERVISOR_DELETE_QUOTA,
        data: { id: this.getData.id },
        visible: true,
      })
    },
  },
}
</script>

<style lang="sass">
.quota-single
  margin-bottom: 18px
  display: flex
  justify-content: space-between
  align-items: center
  &:last-of-type
    margin-bottom: 0
  .desktopWrapper
    display: flex

@media (max-width: 1200px)
  .quota-single
    flex-direction: column
    align-items: flex-start
    .quota-day
      &__title-slash
        margin: 0 4px
      &__time
        order: 1
        flex: auto
        align-items: center
        justify-content: space-between
        width: 100%
        margin: 22px 0 0
        .mobileWrapper
          display: flex
          align-items: center
          justify-content: center
        &-val
          background: #fff
          border: none
          font-size: 16px
          margin: 0
          padding: 0
          &--long
            justify-content: flex-start

      &__icons
        display: flex
        align-items: center
        justify-content: space-between
        column-gap: 12px
        .details-show-icon
          display: flex
          justify-content: center
          align-items: center
          width: 32px
          height: 32px
          border-radius: 8px
          background: #f4f4f5
          border: 1px solid #E4E7ED
          .arrow
            transition: transform 0.3s ease
            &.rotated
              transform: rotate(180deg)
            &.iq-icon *
              fill: none
              stroke: $fontColor

    .mobile-detail
      width: 100%
      order: 2
      padding-bottom: 4px
</style>
